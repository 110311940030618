<template>
  <KTCard
    :page="page"
    :isForm="true"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label> Título: <span class="text-danger">*</span> </template>
        <template #default>
          <b-form-input
            size="lg"
            v-model="form.titulo"
            placeholder="Digite o título"
            autocomplete="off"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Objetivo: <span class="text-danger">*</span>
        </template>
        <template #default>
          <b-form-textarea
            size="lg"
            v-model="form.objetivo"
            placeholder="Digite o objetivo"
            rows="5"
            autocomplete="off"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label> Moeda: <span class="text-danger">*</span> </template>
        <template #default>
          <KTSelect
            :options="form_options.moeda"
            v-model="form.idmoeda"
            disabled
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Atividade: <span class="text-danger">*</span>
        </template>
        <template #default>
          <KTSelect
            :options="form_options.atividade"
            v-model="form.idtipo_plano_investimento"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group
        label-size="lg"
        label-cols-md="4"
        label-cols-lg="3"
        :class="{ 'mb-0': !isPP }"
      >
        <template #label>
          Tempo para análise econômica e financeira:
          <span class="text-danger">*</span>
          <Tooltip
            title="Esta informação foi definida no momento do cadastro do Plano, não é mais possível alterá-la."
          />
        </template>
        <template #default>
          <b-form-radio-group
            size="lg"
            v-model.number="form.tempo_analise_economica_financeira"
            :options="form_options.analise"
            disabled
          ></b-form-radio-group>
        </template>
      </b-form-group>
      <b-form-group
        label-size="lg"
        label-cols-md="4"
        label-cols-lg="3"
        class="mb-0"
        v-if="isPP"
      >
        <template #label>
          Incidência de impostos, taxas ou tributos sobre a comercialização:
          <span class="text-danger mr-2">*</span>
          <Tooltip
            title="Caso não possua incidência de impostos, taxas ou tributos sobre a comercialização, por favor, informar o valor 0 (zero) neste campo."
          />
        </template>
        <template #default>
          <b-input-group size="lg" append="%">
            <KTInputNumeric
              v-model="form.impostos_sobre_receita"
              :precision="2"
              placeholder="Digite o percentual"
              autocomplete="off"
              :required="!finalizado"
              :disabled="finalizado"
            />
          </b-input-group>
        </template>
      </b-form-group>
    </template>
  </KTCard>
</template>

<script>
import CommonDataService from "@/core/services/commondata.service";
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";
import Tooltip from "@/view/layout/extras/Tooltip.vue";

export default {
  name: "PipDadosGerais",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  components: {
    Tooltip
  },
  data() {
    return {
      page: {
        icon: "la la-file-alt",
        title: "Dados Gerais",
        description: `Os campos abaixo são de preenchimento obrigatório.
          No caso de dúvidas consulte a ajuda no canto inferior direito da tela.`
      },
      form: {},
      form_options: {
        atividade: [],
        moeda: [],
        analise: [
          { text: "5 anos", value: 5 },
          { text: "6 anos", value: 6 },
          { text: "7 anos", value: 7 },
          { text: "8 anos", value: 8 },
          { text: "9 anos", value: 9 },
          { text: "10 anos", value: 10 }
        ]
      },
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/dados_gerais`;
    },
    manual() {
      return {
        session: "dados_gerais",
        tipo: this.form.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    isPP() {
      return this.form.tipo_plano_investimento == 1;
    }
  },
  methods: {
    onSubmit() {
      this.ApiSubmitUpdate(null, this.form);
    },
    async getPageData() {
      const requests = [this.Api.GetData(""), CommonDataService.getMoeda()];
      const resolveFuntion = async (res) => {
        // Requests index's
        const R_GETDATA = 0;
        const R_MOEDA = 1;

        this.form = res[R_GETDATA].plano ?? {};
        this.form_options.moeda = res[R_MOEDA];
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);

        this.form_options.atividade =
          await CommonDataService.getTipoPlanoInvestimento(
            this.form.tipo_plano_investimento
          );
      };

      this.ApiGetPageData(requests, resolveFuntion);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
